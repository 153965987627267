import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import Blockquote from "../components/blockquote"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"

const galleryItems = [
  {
    key: 1,
    title: "Top Draft Sports Bar",
    images: ["/images/projects/top-draft-project1.jpg"],
  },
  {
    key: 2,
    title: "Back Painted Glass Process",
    images: ["/images/projects/top-draft-project2.jpg"],
  },
  {
    key: 3,
    title: "Finished Pannels",
    images: ["/images/projects/top-draft-project3.jpg"],
  },
]

const BackPaintedGlassAtlantaGaTopDraftSportsBar = () => {
  return (
    <PageLayout>
      <SEO
        title="Back Painted Glass Atlanta | Top Draft Sports Bar | ArtVue Glass"
        ogDescription="When the Omni Atlanta Hotel at CNN Center went through a renovation, the Top Draft Sports Bar received a colorful glass addition."
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>Top Draft Sports Bar, Atlanta, GA</h1>
            <p>
              The Omni Atlanta Hotel at CNN Center is located directly next to State Farm Arena, the Georgia World Congress Center, and Mercedes-Benz Stadium. When undergoing a renovation to the south tower, the Top Draft Sports Bar received a colorful glass addition.
            </p>
            <p>
              The project consists of ten panels totaling 366 square feet of ¼-inch, low iron, tempered backpainted glass with polished edges. Six panels were 69 by 106 inches, and four panels were 69 by 32 inches.
            </p>
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
        galleryTitle="Project images:"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <Blockquote>We work with all of our customers to understand their finished application so we can produce a final product that meets their needs the first time. Consistent color quality, panel tolerancing, and quick turnaround time were important factors on this project. Since all the panels were being installed adjacent to each other, we made sure to prepare a single batch of paint for all the panels. Also, the panels had to fit together without any gaps, so we held the panel size tolerancing to +/- 1/32 inch. – John Tagle, President, ArtVue Glass.</Blockquote>
                      <FormWrapper
              modal
              buttonProps={{
                content: "Request A Quote",
                size: "tiny",
                icon: "file pdf outline",
              }}
            >
              <RequestQuote />
            </FormWrapper>
          </div>
        }
      />
    </PageLayout>
  )
}

export default BackPaintedGlassAtlantaGaTopDraftSportsBar